.content {
  padding: 0 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 5px; /* 5px rounded corners */
}

.content-light {
  background: #fff;
}

.content-dark {
  background-color: #262626;
}

@media only screen and (max-width: 600px) {
  .content {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}
