.groupCard {
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
  /* height: 250px; */
  /* max-height: 270px; */
  /* max-height: 200px; */
  height: 220px;
  width: auto;
  /* width: 200px; */
  /* width: 250px;
  height: 150px;
  margin: 0 10px 0 10px; */
}

.mainContainerGroups {
  padding: 10px;
  margin-top: 10px;
}

.search-container {
  margin-bottom: 20px;
}
.showGroups-container {
  margin-bottom: 20px;
}


/* nuevas tarjetas */
.demo-card-square.mdl-card {
  /* width: 200px;
  height: 320px; */
  width: 250px;
  height: 20px;
  float: left;
  /* margin: 1rem; */
  position: relative;
  background: white;
  border: solid 2px;
  border-color: red;
}

.demo-card-square.mdl-card:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
}

.demo-card-square > .mdl-card__title {
  color: #fff;
  background: #03a9f4;
}

.demo-card-square > .mdl-card__accent {
  background: #ff9800;
}

/* body {
  padding: 20px;
  background: #fafafa;
  position: relative;
} */