.BreadcrumbLinkUser {
  color: #8c8c8c;
  display: inline;
}

.BreadcrumbLinkUserDark {
  color: #f0f0f0;
  display: inline;
}

.breadCrumbFirst {
  font-size: 18px;
}

.breadCrumbGroup {
  font-size: 15px;
}

.BreadcrumbLinkUser:hover {
  color: #722ed1;
}
.BreadcrumbLinkUserDark:hover {
  color: #b37feb;
}

.homeIcon {
  margin-right: 10px;
}

.BreadcrumbItem {
  color: #8c8c8c;
  display: inline;
}

.BreadcrumbItemDark {
  color: #bfbfbf;
  display: inline;
}
