.main-container {
  height: 100vh;
}

.box {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginCard {
  width: 60%;
}

@media only screen and (max-width: 991px) {
  .loginCard {
    width: 100%;
  }
}

.welcome {
  background: #b24592; /* fallback for old browsers */
  background: #9d50bb; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6e48aa,
    #9d50bb
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6e48aa,
    #9d50bb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.login-container {
  width: 100%;
  text-align: center;
}

.sign-up {
  font-weight: bold;
}

.to-sign {
  cursor: pointer;
}

.shadowCardLogin {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.fridaLogo {
  width: 30px;
  height: 30px;
}
