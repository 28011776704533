.mainContainerUser {
  padding: 10px;
}

.paramsContainer {
  width: 90%;
}

.ManageBtn {
  border-radius: 8px;
}

.col-end {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
