.usersTable {
  margin-top: 20px;
}

.userLink {
  color: #722ed1;
}

.ManageBtn {
  border-radius: 8px;
}

.col-end {
  display: flex;
  justify-content: flex-end;
}

.cardBlockAdd {
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.colCenter {
  display: flex;
  justify-content: center;
}

.stepsBtns {
  margin-top: 20px;
}
